import React from 'react'
//import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import Layout from '../../components/Layout'

import banner_2 from '../../images/banners/banner-2.jpg'

const iframeStyle = {
  border: 0
}

const ContactPage = ({
  location,
  data: { site: { siteMetadata: { title } } },
}) => (
    <Layout location={location}>
      <Helmet title={`Contact | ${title}`} />

      <div id="top-banner" className="container-fluid horizontal-section-container clearfix">
        <div className="row">
          <div className="col-xs-12">
            <img src={banner_2} alt="Contact" className="full-width-image" />
          </div>
        </div>
      </div>

      <div id="breadcrumbs-container" className="container-fluid horizontal-section-container hidden-xs clearfix">
        <div className="row">
          <div className="col-xs-12">
            <ol className="breadcrumb">
              <li><Link to="/">Accueil</Link></li>
              {/* eslint-disable-next-line */}
              <li className="active"><a href="#">Contact</a></li>
            </ol>
          </div>
        </div>
      </div>

      <div className="container-fluid horizontal-section-container clearfix">
        <div className="row">

          <div className="col-sm-12">

            <div id="main-page-content" className="section-container main-page-content clearfix">

              <div className="section-content clearfix">

                <h1 className="page_title">Contact</h1>

                <div className="row clearfix">

                  <div className="col-sm-6">
                    {/* eslint-disable-next-line */}
                    <h6>Vous pouvez nous contacter en utilisant le formulaire de contact, ou bien au moyen du lien <a className="email-link" href="#"><span className="backwards">moc.liamg@nehsog.esilge</span></a> pour avoir recours à votre client de messagerie.</h6>

                    <ul className="vertical-simple-list contact-list">

                      <li className="item clearfix">
                        <div className="item-content">
                          <div className="icon"><span className="glyphicon glyphicon-map-marker"></span></div>                                                    
                          <h6 className="title">Galerie commerciale Renoir<br />17 Avenue Auguste Renoir<br />06800 Cagnes-sur-Mer</h6>
                        </div>
                      </li>

                      <li className="item clearfix">
                        <div className="item-content">
                          <div className="icon"><span className="glyphicon glyphicon-earphone"></span></div>
                          <h6 className="title">04 93 73 10 58 (Pasteur Jacques Goguillon)</h6>
                        </div>
                      </li>

                      <li className="item clearfix">
                        <div className="item-content">
                          <div className="icon"><span className="glyphicon glyphicon-pencil"></span></div>                          
                          <h6 className="title"><strong>Siège</strong><br />64, Avenue Docteur Maurice Donat<br />06800 Cagnes-sur-Mer</h6>
                        </div>
                      </li>                      

                      <li className="item clearfix">
                        <div className="item-content">
                          <div className="icon"><span className="glyphicon glyphicon-envelope"></span></div>
                          {/* eslint-disable-next-line */}
                          <h6 className="title"><a className="email-link" href="#"><span className="backwards">moc.liamg@nehsog.esilge</span></a></h6>
                        </div>
                      </li>

                      <li className="item clearfix">
                        <div className="item-content">
                          <div className="icon"><span className="fa fa-clock-o"></span></div>
                          <h6 className="title">
                            <strong>Nos réunions</strong><br />
                            Dimanche : 10h00<br />
                            Jeudi : 19h00 (prières)<br />
                            Prières pour Israël le deuxième jeudi de chaque mois
                          </h6>
                        </div>
                      </li>

                    </ul>

                    <hr />

                    <ul className="social-media-icons clearfix">
                      {/* eslint-disable-next-line */}
                      <li><a href="https://www.facebook.com/eglisegoshen" target="_blank" title="Facebook"><i className="fa fa-facebook"></i></a></li>
                    </ul>

                  </div>

                  {/*} contact form */}
                  <div className="col-sm-6">

                    <div id="contact-form-container" className="clearfix">
                      <form className="validate-form clearfix" action="https://formspree.io/f/mvodpngp" method="POST">
                        <div className="form-group">
                          <label htmlFor="form-name">Nom</label>
                          <input type="text" className="form-control validate-field required" data-validation-type="string" id="form-name" name="name" placeholder="Votre nom" />
                        </div>
                        <div className="form-group">
                          <label htmlFor="form-email">Adresse de courriel</label>
                          <input type="email" className="form-control validate-field required" data-validation-type="email" id="form-email" name="email" placeholder="Votre adresse de courriel" />
                        </div>
                        <div className="form-group">
                          <label htmlFor="form-contact-message">Message</label>
                          <textarea className="form-control message-textarea-height large validate-field required" id="form-contact-message" name="message" placeholder="Votre message"></textarea>
                        </div>
                        <button type="submit" className="btn btn-outline-inverse btn-lg">Envoyer</button>
                        <i className="form-loader fa fa-spinner fa-spin"></i>
                        <div className="form-group form-general-error-container"></div>
                      </form>
                    </div>

                  </div>

                </div>

              </div>

            </div>
          </div>

        </div>
      </div>

      <div id="contact-map" className="container-fluid horizontal-section-container clearfix">        
        <iframe title="Google Maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d721.5628620881423!2d7.149499529209992!3d43.66374006885599!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12cdd3bd9a7dae6d%3A0x894b04ed0fb5ef4!2s17+Avenue+Auguste+Renoir%2C+06800+Cagnes-sur-Mer!5e0!3m2!1sfr!2sfr!4v1565180698357!5m2!1sfr!2sfr" width="800" height="600" frameBorder="0" style={iframeStyle} allowFullScreen={true}></iframe>
      </div>

    </Layout>
  )

export default ContactPage

export const contactPageQuery = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
